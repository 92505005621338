<template>
  <div>
    <v-app-bar
      app
      color="#f6f6f6"
      elevate-on-scroll
      elevation="4"
      v-if="this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.mdAndUp"
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-spacer></v-spacer>

      <!-- <v-toolbar-title>Title</v-toolbar-title> -->

      <v-spacer></v-spacer>

      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <span
            v-bind="attrs"
            v-on="on"
            style="cursor: pointer"
            class="mx-5 mr-10"
          >
            <v-chip link>
              <v-badge dot bottom offset-y="10" offset-x="10" color="green">
                <v-avatar size="40">
                  <v-img
                    :src="userData.photo ? userData.photo : 'assets/user.jpeg'"
                  />
                </v-avatar>
              </v-badge>

              <!-- <span>{{ userData?.nombre_completo }}</span> -->
            </v-chip>
          </span>
        </template>
        <v-list width="250" class="py-0">
          <v-list-item two-line>
            <v-list-item-avatar>
              <v-img
                width="50"
                :src="userData.photo ? userData.photo : 'assets/user.jpeg'"
              />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                <!-- {{ userData.nombre }} -->
              </v-list-item-title>
              <v-list-item-subtitle> En Linea </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item
            v-for="{ icon, title } in menus"
            :key="icon"
            link
            @click="logout()"
          >
            <v-list-item-icon>
              <v-icon>{{ icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <!--! SIDEBAR -->

    <v-navigation-drawer v-model="drawer" app>
      <v-img src="@/assets/logo-app-portal.jpeg">
        <div class="text-center mt-4"></div>
      </v-img>

      <v-divider></v-divider>
      <v-list>
        <v-list-item-group v-model="group">
          <div v-for="[icon, text, routeName, bandera] in links" :key="icon">
            <v-list-item
              v-if="bandera"
              link
              @click="navigateToRoute(routeName)"
            >
              <v-list-item-icon>
                <v-icon>{{ icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ text }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "AppNavigation",
  data() {
    return {
      links: [],
      group: null,
      drawer: true,

      menus: [{ title: "Salir", icon: "mdi-logout" }],
    };
  },
  created() {},
  methods: {
    logout() {
      this.$store.dispatch("user/logout");
    },
    navigateToRoute(routeName) {
      this.$router.push({ name: routeName }).catch((err) => {
        console.log(err);
      });
    },
    contruccionMenu() {
      console.log(this.policeData?.jerarquia);
      if (this.policeData.jerarquia) {
        this.links = [
          ["mdi-home", "Inicio", "home", true],
          [
            "mdi-white-balance-sunny",
            "Fiesta del Sol 2024",
            "admin-servicios",
            true,
          ],

          ["mdi-android", "Entorno Android", "androidDownload", true],
          ["mdi-apple", "Entorno Apple", "appleDownload", true],

          [
            "mdi-card-account-details",
            "Credencial Digital",
            "credencial",
            true,
          ],
          ["mdi-youtube", "Tutoriales", "tutorials", true],
        ];
      } else {
        this.links = [
          ["mdi-home", "Inicio", "home", true],
          [
            "mdi-white-balance-sunny",
            "Fiesta del Sol 2024",
            "admin-servicios",
            true,
          ],
          ["mdi-android", "Entorno Android", "androidDownload", true],
          ["mdi-apple", "Entorno Apple", "appleDownload", true],

          ["mdi-youtube", "Tutoriales", "tutorials", true],
        ];
      }
    },
  },
  computed: mapState({
    userData: (state) => state.user.userData,
  }),
  mounted() {
    this.policeData = JSON.parse(localStorage.getItem("userData"));
    this.contruccionMenu();
  },
};
</script>

<style lang="scss" scoped></style>
